export const EXAMINEE_STATUS = {
  ABSENT: {
    value: 1,
    label: '缺考'
  },
  UNSUBMIT: {
    value: 2,
    label: '未提交'
  },
  SUBMITED: {
    value: 3,
    label: '已提交'
  },
  UNPASS: {
    value: 4,
    label: '未通过'
  },
  PASS: {
    value: 5,
    label: '通过'
  }
}

/**
 * 获取用户考试结果中文描述，默认"缺考"
 * @param value
 * @returns {*}
 */
export const getStatusByValue = value => {
  const statusList = Object.keys(EXAMINEE_STATUS)
  for (const status of statusList) {
    if (EXAMINEE_STATUS[status].value === value) {
      return EXAMINEE_STATUS[status]
    }
  }
  return EXAMINEE_STATUS.ABSENT
}
